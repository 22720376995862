<template>
  <svg
    width="40"
    height="29"
    viewBox="0 0 40 29"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.403 28.5895H0.597015C0.268657 28.5895 0 28.3209 0 27.9925V7.75373C0 7.42537 0.268657 7.15672 0.597015 7.15672H39.403C39.7313 7.15672 40 7.42537 40 7.75373V27.9925C40 28.3209 39.7313 28.5895 39.403 28.5895ZM1.19403 27.3955H38.806V8.35075H1.19403V27.3955Z"
      fill="inherit"
    />
    <path
      d="M36.8657 4.97761H3.13437C2.80601 4.97761 2.53735 4.70896 2.53735 4.3806C2.53735 4.05224 2.80601 3.78358 3.13437 3.78358H36.8657C37.1941 3.78358 37.4627 4.05224 37.4627 4.3806C37.4627 4.70896 37.1941 4.97761 36.8657 4.97761Z"
      fill="inherit"
    />
    <path
      d="M33.4925 1.60448H6.50742C6.17906 1.60448 5.9104 1.33582 5.9104 1.00746C5.9104 0.679105 6.17906 0.410448 6.50742 0.410448H33.4925C33.8208 0.410448 34.0895 0.679105 34.0895 1.00746C34.0895 1.33582 33.8208 1.60448 33.4925 1.60448Z"
      fill="inherit"
    />
    <path
      d="M32.6568 25.2164H7.34331C7.01496 25.2164 6.7463 24.9478 6.7463 24.6194C6.7463 23.5448 5.88063 22.6791 4.806 22.6791C4.47764 22.6791 4.20898 22.4104 4.20898 22.0821V13.6642C4.20898 13.3358 4.47764 13.0672 4.806 13.0672C6.02988 13.0672 6.7463 12.3507 6.7463 11.1269C6.7463 10.7985 7.01496 10.5299 7.34331 10.5299H32.6568C32.9851 10.5299 33.2538 10.7985 33.2538 11.1269C33.2538 12.3507 33.9702 13.0672 35.1941 13.0672C35.5224 13.0672 35.7911 13.3358 35.7911 13.6642V22.1119C35.7911 22.4403 35.5224 22.709 35.1941 22.709C34.1194 22.709 33.2538 23.5746 33.2538 24.6493C33.2538 24.9478 32.9851 25.2164 32.6568 25.2164ZM7.88063 24.0224H32.0896C32.3284 22.7687 33.3135 21.7836 34.5672 21.5448V14.2015C33.2239 13.9925 32.2985 13.0672 32.0896 11.7239H7.88063C7.67167 13.0672 6.7463 13.9925 5.40301 14.2015V21.5448C6.65675 21.7836 7.64182 22.7687 7.88063 24.0224Z"
      fill="inherit"
    />
    <path
      d="M12.4179 18.4701H10.7164C10.388 18.4701 10.1194 18.2015 10.1194 17.8731C10.1194 17.5448 10.388 17.2761 10.7164 17.2761H12.4179C12.7462 17.2761 13.0149 17.5448 13.0149 17.8731C13.0149 18.2015 12.7462 18.4701 12.4179 18.4701Z"
      fill="inherit"
    />
    <path
      d="M29.2835 18.4701H27.6119C27.2835 18.4701 27.0149 18.2015 27.0149 17.8731C27.0149 17.5448 27.2835 17.2761 27.6119 17.2761H29.2835C29.6119 17.2761 29.8806 17.5448 29.8806 17.8731C29.8806 18.2015 29.6119 18.4701 29.2835 18.4701Z"
      fill="inherit"
    />
    <path
      d="M20.0001 21.8433C17.821 21.8433 16.0299 20.0522 16.0299 17.8731C16.0299 15.694 17.821 13.903 20.0001 13.903C22.1792 13.903 23.9702 15.694 23.9702 17.8731C23.9702 20.0522 22.1792 21.8433 20.0001 21.8433ZM20.0001 15.097C18.4777 15.097 17.2239 16.3507 17.2239 17.8731C17.2239 19.3955 18.4777 20.6493 20.0001 20.6493C21.5224 20.6493 22.7762 19.3955 22.7762 17.8731C22.7762 16.3209 21.5224 15.097 20.0001 15.097Z"
      fill="inherit"
    />
  </svg>
</template>
<script>
export default {
  name: 'FiveCashIcon',
}
</script>
